import {Injectable} from '@angular/core';
import {Employee} from "../model/employee";
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import * as moment from "moment";
import {Leave, SickLeave} from "../model/leave";
import {Payroll, Payslip} from "../model/payroll";
import {getCurrentBusiness} from "../utils";
import {DocumentConverter} from "../document.repository";
import {MedicalRecord} from "../model/medicalRecord";
import {Education} from "../model/education";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class EmployeeRepository extends Repository<Employee> {

    constructor(firestore: FirestoreWrapper) {
        let documentConverter = new DocumentConverter();
        super(firestore, new EmployeeConverter(documentConverter))
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${business.id}/employees`).withConverter(this.converter);
    }

}

class EmployeeConverter implements FirestoreDataConverter<Employee> {

    constructor(private documentConverter: DocumentConverter) {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Employee {
        const data = snapshot.data(options)!
        let employee = new Employee();
        employee.id = data['id']
        employee.fullName = data['fullName']
        employee.address = data['address']
        employee.phoneNumber = data['phoneNumber']
        employee.taxNumber = data['taxNumber']
        employee.birthDate = data['birthDate'] ? moment(data['birthDate'], 'DD/MM/YYYY') : null
        employee.birthPlace = data['birthPlace']
        employee.function = data['function']
        employee.hourlyRate = parseFloat(data['hourlyRate'])
        employee.nextMedicalAppointment = data['nextMedicalAppointment'] ? moment(data['nextMedicalAppointment'], 'DD/MM/YYYY') : null
        employee.identificationDocument = this.documentConverter.fromData(data['identificationDocument'])
        employee.jobContract = this.documentConverter.fromData(data['jobContract'])
        employee.employmentCenterDocument = this.documentConverter.fromData(data['employmentCenterDocument'])
        employee.c2Document = this.documentConverter.fromData(data['c2Document'])
        employee.terminationLetter = this.documentConverter.fromData(data['terminationLetter'])
        employee.version = data['version'] || 0

        employee.payroll = this.convertPayroll(data['payroll'])
        this.convertLeaveProfile(data, employee)
        this.convertSickLeaveProfile(data, employee)
        this.convertMedicalProfile(data, employee)
        this.convertEducationProfile(data, employee)
        return employee
    }

    private convertLeaveProfile(data: DocumentData, employee: Employee) {
        let datum = data['leaveProfile'] as any[] || [];
        employee.leaveProfile = datum.map(value => {
            let leave = new Leave();
            leave.from = moment(value.from, 'DD/MM/YYYY HH:mm')
            leave.to = moment(value.to, 'DD/MM/YYYY HH:mm')

            return leave
        })
    }

    private convertSickLeaveProfile(data: DocumentData, employee: Employee) {
        let datum = data['sickLeaveProfile'] as any[] || [];
        employee.sickLeaveProfile = datum.map(value => {
            let leave = new SickLeave();
            leave.from = moment(value.from, 'DD/MM/YYYY HH:mm')
            leave.to = moment(value.to, 'DD/MM/YYYY HH:mm')
            leave.certificate = this.documentConverter.fromData(value.certificate)!

            return leave
        })
    }

    private convertMedicalProfile(data: DocumentData, employee: Employee) {
        let datum = data['medicalProfile'] as any[] || [];
        employee.medicalProfile.records = datum.map(value => {
            let record = new MedicalRecord();
            record.id = value.id
            record.expectedDate = value.expectedDate ? moment(value.expectedDate, 'DD/MM/YYYY HH:mm') : null
            record.date = value.date ? moment(value.date, 'DD/MM/YYYY HH:mm') : null
            record.certificate = this.documentConverter.fromData(value.certificate)!
            record.description = value.description
            record.supplier = value.supplier

            return record
        })
    }

    private convertEducationProfile(data: DocumentData, employee: Employee) {
        let datum = data['educationProfile'] as any[] || [];
        employee.educationProfile.records = datum.map(value => {
            let record = new Education();
            record.id = value.id
            record.expectedDate = value.expectedDate ? moment(value.expectedDate, 'DD/MM/YYYY HH:mm') : null
            record.date = value.date ? moment(value.date, 'DD/MM/YYYY HH:mm') : null
            record.certificate = this.documentConverter.fromData(value.certificate)!
            record.description = value.description
            record.supplier = value.supplier

            return record
        })
    }

    toFirestore(employee: Employee): DocumentData {
        let newVar = {
            id: employee.id,
            fullName: employee.fullName,
            address: employee.address,
            phoneNumber: employee.phoneNumber,
            taxNumber: employee.taxNumber,
            birthDate: employee.birthDate ? employee.birthDate.format('DD/MM/YYYY') : null,
            birthPlace: employee.birthPlace,
            function: employee.function,
            version: employee.version,
            // @ts-ignore
            hourlyRate: typeof employee.hourlyRate == "string" ? parseFloat(employee.hourlyRate.replace('.', '').replace(',', '.')) : employee.hourlyRate,
            nextMedicalAppointment: employee.nextMedicalAppointment ? employee.nextMedicalAppointment?.format('DD/MM/YYYY') : null,

            identificationDocument: this.documentConverter.toData(employee.identificationDocument),
            jobContract: this.documentConverter.toData(employee.jobContract),
            employmentCenterDocument: this.documentConverter.toData(employee.employmentCenterDocument),
            c2Document: this.documentConverter.toData(employee.c2Document),
            terminationLetter: this.documentConverter.toData(employee.terminationLetter),
            leaveProfile: this.leaveProfileToFirestore(employee),
            sickLeaveProfile: this.sickLeaveProfileToFirestore(employee),
            medicalProfile: this.medicalProfileToFirestore(employee),
            educationProfile: this.educationProfileToFirestore(employee),
            payroll: this.payrollToFirestore(employee)

        };
        return newVar
    }

    private leaveProfileToFirestore(employee: Employee) {
        return employee.leaveProfile.map(record => {

            return {
                from: record.from.format('DD/MM/YYYY HH:mm'),
                to: record.to.format('DD/MM/YYYY HH:mm'),
            }
        });
    }

    private sickLeaveProfileToFirestore(employee: Employee) {
        return employee.sickLeaveProfile.map(record => {

            return {
                certificate: this.documentConverter.toData(record.certificate),
                from: record.from.format('DD/MM/YYYY HH:mm'),
                to: record.to.format('DD/MM/YYYY HH:mm'),
            }
        });
    }

    private medicalProfileToFirestore(employee: Employee) {
        return employee.medicalProfile.records.map(record => {

            return {
                id: record.id,
                description: record.description,
                certificate: this.documentConverter.toData(record.certificate),
                date: record.date?.format('DD/MM/YYYY HH:mm') || null,
                expectedDate: record.expectedDate?.format('DD/MM/YYYY HH:mm') || null,
                supplier: {id: record.supplier.id, name: record.supplier.name, taxNumber: record.supplier.taxNumber}
            }
        });
    }

    private educationProfileToFirestore(employee: Employee) {
        return employee.educationProfile.records.map(record => {

            return {
                id: record.id,
                description: record.description,
                certificate: this.documentConverter.toData(record.certificate),
                date: record.date?.format('DD/MM/YYYY HH:mm') || null,
                expectedDate: record.expectedDate?.format('DD/MM/YYYY HH:mm') || null,
                supplier: {id: record.supplier.id, name: record.supplier.name, taxNumber: record.supplier.taxNumber}
            }
        });
    }

    private payrollToFirestore(employee: Employee) {
        return {
            records: employee.payroll.records.map(record => {
                return {
                    number: record.number,
                    month: record.month,
                    year: record.year,
                    amount: record.amount,
                    paidDate: record.paidDate ? record.paidDate.format('DD/MM/YYYY') : null,
                    receipt: this.documentConverter.toData(record.receipt),
                    payslipDocument: this.documentConverter.toData(record.payslipDocument),
                    confirmedPayslipDocument: this.documentConverter.toData(record.confirmedPayslipDocument),
                    notes: record.notes || null
                }
            })
        }
    }

    private convertPayroll(data: any) {

        let payroll = new Payroll();
        let records = data ? data['records'] : []

        payroll.records = records.map(value => {
            let payslip = new Payslip();
            payslip.number = value.number
            payslip.month = value.month
            payslip.year = value.year || 2023
            payslip.amount = value.amount
            payslip.paidDate = value['paidDate'] ? moment(value['paidDate'], 'DD/MM/YYYY') : null
            payslip.receipt = this.documentConverter.fromData(value.receipt)
            payslip.payslipDocument = this.documentConverter.fromData(value.payslipDocument)
            payslip.confirmedPayslipDocument = this.documentConverter.fromData(value.confirmedPayslipDocument)
            payslip.notes = value['notes'] || null

            return payslip
        })

        return payroll
    }
}
