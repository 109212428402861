import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotificationRepository} from "./notifications/notification.repository";
import {Notification} from "./model/notification";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar, private notificationRepository: NotificationRepository) {
    }

    notify(message: string) {
        this.snackBar.open(message, undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5 * 1000,
            panelClass: 'notificationSnack'
        });
    }

    notifyError(message: string) {
        this.snackBar.open(message, 'Chiudi', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['notificationSnack', 'error'],
        });
    }

    notifyFormError() {
        let message = $localize`:@@cannot_save:Impossibile salvare, il form contiene errori`;
        this.notifyError(message)
    }


    getAllNotAcknowledged() {
        return this.notificationRepository.getAllNotAcknowledged()
    }

    markAcknowledged(notification: Notification) {
        notification.acknowledged = true
        this.notificationRepository.save(notification)
    }
}
