import {Injectable} from '@angular/core';
import {
    collectionData,
    DocumentData,
    FirestoreDataConverter,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    where
} from "@angular/fire/firestore";
import {Supplier} from "../model/supply";
import {Repository} from "../persistence/repository";
import {getCurrentBusiness} from "../utils";
import {ProjectConverter} from "../projects-management/project-repository.service";
import {AddressConverter} from "../authentication/addressConverter";
import {Address} from "../model/address";
import {DocumentConverter} from "../document.repository";
import {Observable} from "rxjs";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class SupplierRepository extends Repository<Supplier> {

    documentConverter: DocumentConverter
    projectConverter: ProjectConverter

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new SupplierConverter(new AddressConverter()))

        this.documentConverter = new DocumentConverter();
        this.projectConverter = new ProjectConverter(this.documentConverter);
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${business.id}/suppliers`).withConverter(this.converter);
    }

    override getAll(): Observable<Supplier[]> {
        return collectionData(query(
            this.getCollectionReference(),
            where('_type', 'in', ['supplier', 'medical_center', 'educational_center'])
        ))
    }

    getAllTypes() {
        return super.getAll()
    }
}

class SupplierConverter implements FirestoreDataConverter<Supplier> {
    constructor(private addressConverter: AddressConverter) {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Supplier {
        const data = snapshot.data(options)!
        let supplier = new Supplier();
        supplier.id = data['id']
        supplier.name = data['name']
        supplier.address = data['address'] ? this.addressConverter.fromFirestore(data['address']) : new Address()
        supplier.taxNumber = data['taxNumber']
        supplier.sdi = data['sdi']
        supplier.iban = data['iban']
        supplier.email = data['email']
        supplier.phoneNumber = data['phoneNumber']
        supplier._type = data['_type']
        supplier.version = data['version'] || 0
        return supplier
    }

    toFirestore(supplier: Supplier): DocumentData {
        return {
            id: supplier.id,
            name: supplier.name,
            address: supplier.address ? this.addressConverter.toFirestore(supplier.address) : null,
            taxNumber: supplier.taxNumber || null,
            sdi: supplier.sdi || null,
            iban: supplier.iban || null,
            email: supplier.email || null,
            phoneNumber: supplier.phoneNumber || null,
            _type: supplier._type,
            version: supplier.version
        }
    }
}
