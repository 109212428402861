import {Component, OnInit} from '@angular/core';
import {Bouncer} from "./bouncer.service";
import {NotificationService} from "./notification.service";
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from "@angular/router";
import {NavigatorService} from "./navigator.service";
import {Business} from "./model/business";
import {first, Observable, Subject, takeUntil} from "rxjs";
import {environment} from "../environments/environment";
import {connectAuthEmulator, getAuth} from "@angular/fire/auth";
import {connectFirestoreEmulator, getFirestore} from "@angular/fire/firestore";
import {connectStorageEmulator, getStorage} from "@angular/fire/storage";
import {BusinessRepository} from "./authentication/business.repository";
import {MatSelectChange} from "@angular/material/select";
import {compareEntities} from "./utils";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SpinnerDialogComponent} from "./spinner-dialog/spinner-dialog.component";
import {ReminderService} from "./notifications/reminder.service";
import {Notification} from "./model/notification";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    isMenuShowable: boolean = false;
    isErpEnabled: boolean = false;
    isInvoicingEnabled: boolean = false;
    opened: boolean = true;
    displayName: string;
    business: Business | null = null
    isSuperAdmin: boolean;
    businesses: Business[] = [];

    unsubscribe = new Subject<void>();
    loading = true;
    matDialogRef: MatDialogRef<any, any>
    notificationObservable: Observable<Notification[]>;

    constructor(public bouncer: Bouncer, private notificationService: NotificationService,
                private navigatorService: NavigatorService,
                private businessRepository: BusinessRepository,
                private googleAnalyticsService: GoogleAnalyticsService,
                private reminderService: ReminderService,
                private dialog: MatDialog,
                private router: Router
    ) {
        if (environment.useEmulators) {
            console.log("USING EMULATORS")
            connectAuthEmulator(getAuth(), "http://localhost:9099", {disableWarnings: true})
            connectFirestoreEmulator(getFirestore(), "localhost", 8081)
            connectStorageEmulator(getStorage(), "localhost", 9199)
        }

        if(environment.production) {
            console.log("pan version", environment.pan.version)
        }

        if (environment.googleAnalytics.enabled) {
            this.googleAnalyticsService.init()
        }

        addEventListener('entity_saved', this.onEntitySaved.bind(this))

        this.router.events.pipe(takeUntil(this.unsubscribe))
            .subscribe((routerEvent) => {
                this.checkRouterEvent(routerEvent as RouterEvent);
            });

    }

    ngOnInit(): void {
        this.bouncer.activateAuth()

        this.bouncer.onLoginCompleted().subscribe(({user, business, config}) => {
            console.log("onLoginCompleted", user, business, config)

            this.displayName = user.displayName || ''
            this.isMenuShowable = user.emailVerified
            this.isSuperAdmin = config.superAdmin
            this.business = business
            this.isErpEnabled = config.erp
            this.isInvoicingEnabled = config.invoicing

            if(this.isSuperAdmin) this.getAllBusinesses()

            this.reminderService.createNotificationFromReminders()
            this.notificationObservable = this.notificationService.getAllNotAcknowledged()

            if(business.hasMissingData()) {
                this.navigatorService.goToBusinessPage()
            }

        })


    }

    onEntitySaved(el) {
        //this.notificationService.notify(`${el.detail.constructor.name} salvato`)
        this.notificationService.notify(`Salvataggio completato`)
        this.navigatorService.onEntitySaved(el.detail)
    }


    toggleSidebar() {

    }

    logout() {
        this.bouncer.logout().then(() => {
            this.business = null
            return this.isMenuShowable = false;
        })
    }

    getAllBusinesses() {
        this.businessRepository.getAll().pipe(first()).subscribe(businesses => {
            this.businesses = businesses
        })
    }

    businessSelected(event: MatSelectChange) {
        console.log("ev", event.value)
        this.bouncer.storeInspectedBusiness(event.value)
        location.reload()
    }

    compareEntities(a, b) {
        return compareEntities(a, b)
    }

    getMainPage() {
        return this.bouncer.isLoggedIn() ? '/clients' : '/welcome'
    }


    checkRouterEvent(routerEvent: RouterEvent): void {

        if (routerEvent instanceof NavigationStart) {
            this.loading = true;
            this.matDialogRef = this.dialog.open(SpinnerDialogComponent, {
                width: '100px',
                height: '100px',
                backdropClass: 'loadingSpinnerBackdrop'
            })
        }

        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.loading = false;
            this.matDialogRef.close()

        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
    }

    unreadNotificationsNumber(notifications: Notification[]) {
        return notifications.length
    }

    markNotificationAcknowledged(notification: Notification) {
        this.notificationService.markAcknowledged(notification)
    }
}

