import * as moment from "moment";
import {Moment} from "moment";
import {Deliverable, Supplier, Supply} from "./supply";
import {round} from "../utils";
import {Accountant} from "./accountant";
import {Employee} from "./employee";
import {MedicalRecord} from "./medicalRecord";
import {Education} from "./education";
import {FiscalDocument} from "./fiscalDocument";
import {Project} from "./project";
import {Business} from "./business";
import {GenericSender} from "./invoiceSender";
import {Entity} from "./entity";

export enum InvoiceStatus {
    Draft,
    Sent,
    Received,
    Paid,
    Confirmed,
}

export enum SdiStatus {
    NotSent,
    Sending, // can be sent to SDI
    Sent,    // sent to SDI
    Error,
    Delivered, // delivered to client
}

export enum SenderType {
    Supplier,
    Accountant,
    Business,
    Generic
}


export enum QuoteStatus {
    Draft,
    Sent,
}

export enum FiscalDocumentType {
    Invoice = 'INVOICE',
    CreditNote = 'CREDIT_NOTE',
}

export const fiscalDocumentTypes = [
    {value: FiscalDocumentType.Invoice, name: $localize`:@@invoice:Fattura`},
    {value: FiscalDocumentType.CreditNote, name: $localize`:@@credit_note:Nota di credito`},
];

export class Quote extends FiscalDocument {
    status: QuoteStatus = QuoteStatus.Draft
    type: string = 'Preventivo';

    isDraft() {
        return this.status == QuoteStatus.Draft
    }

}


export class Invoice extends FiscalDocument {
    type: FiscalDocumentType = FiscalDocumentType.Invoice;

    flowId: string | null = null
    imported: boolean = false;
    dueDate: Moment | null
    status: InvoiceStatus = InvoiceStatus.Draft
    sdiStatus: SdiStatus = SdiStatus.NotSent
    private _paidDate: Moment | null = null;
    supplier: Supplier | null = null;
    supply: Supply | null = null;
    accountant: Accountant | null = null;
    employee: Employee | null = null;
    medicalRecord: MedicalRecord | null = null;
    education: Education | null = null;

    markAsSending() {
        this.sdiStatus = SdiStatus.Sending
        this.sentDate = moment()
    }

    markAsSent() {
        this.sdiStatus = SdiStatus.Sent
    }

    markAsConfirmed() {
        this.status = InvoiceStatus.Confirmed
    }

    isDraft() {
        return this.status == InvoiceStatus.Draft && this.sdiStatus == SdiStatus.NotSent
    }

    isConfirmed() {
        return this.status == InvoiceStatus.Confirmed
    }

    isSent() {
        return this.sdiStatus == SdiStatus.Sent || this.status == InvoiceStatus.Sent
    }

    isDelivered() {
        return this.sdiStatus == SdiStatus.Delivered
    }

    override isError(): boolean {
        return this.sdiStatus == SdiStatus.Error
    }

    isReceived() {
        return this.status == InvoiceStatus.Received
    }

    override isPaid() {
        return this.status == InvoiceStatus.Paid
    }

    isOverdue() {
        return this.status != InvoiceStatus.Paid && moment().isAfter(this.dueDate)
    }

    getCurrentStatus() {
        if(this.isPaid()) return this.status

        // @ts-ignore
        if(this.sdiStatus != SdiStatus.NotSent) return this.sdiStatus

        return this.status
    }

    isPayable() {
        return this.type == FiscalDocumentType.Invoice;
    }

    isPaidDatePresent() {
        return this._paidDate != null;
    }

    markAsPaid() {
        this.status = InvoiceStatus.Paid
    }

    markAsReceived() {
        this.status = InvoiceStatus.Received
    }

    markAsDraft() {
        this.status = InvoiceStatus.Draft
    }

    isSenderGeneric() {
        return this.senderType == SenderType.Generic
    }

    isSending() {
        return !!this.sdiStatus && this.sdiStatus === SdiStatus.Sending
    }

    isLinkedToSupply() {
        return this.supply != null
    }

    isLinkedToAccountant() {
        return this.accountant != null;
    }

    isLinkedToMedicalRecord() {
        return this.medicalRecord != null;
    }

    isLinkedToEducation() {
        return this.education != null;
    }

    isLinked() {
        return this.isLinkedToSupply() || this.isLinkedToAccountant() || this.isLinkedToMedicalRecord() || this.isLinkedToEducation()
    }

    get paidDate(): moment.Moment | null {
        return this._paidDate;
    }

    set paidDate(paidDate: moment.Moment | null) {
        this._paidDate = paidDate;

        if(paidDate && !this.isPaid()) {
            this.markAsPaid()
        }

        if(!paidDate && !this.isSent()) {
            this.markAsDraft()
        }
    }

    static makeNewOutboundInvoice(business: Business) {
        let invoice = new Invoice()
        invoice.senderType = SenderType.Business
        invoice.date = moment()
        invoice.dueDate = moment().add(30, 'days')
        invoice.currency = "Euro"
        invoice.sender = business
        return invoice
    }

    static makeNewInboundInvoice(business: Business, type: string | null, id: string | null,  projectId: string | null, supplierId: string | null) {
        let invoice = new Invoice()
        invoice.sender = new GenericSender()
        invoice.senderType = SenderType.Generic
        invoice.date = moment()
        invoice.dueDate = moment().add(30, 'days')
        invoice.currency = "Euro"
        invoice.receiver = business!

        if (type == Accountant.name) {
            invoice.accountant = {id: id} as Accountant
        } else if (type == Deliverable.name) {
            invoice.project = {id: projectId} as Project
            invoice.supply = {id: id} as Supply
            invoice.supplier = {id: supplierId} as Supplier
        }

        return invoice
    }


}

export class PriceModifier {
    type: string = 'SC'
    percentage: number | null = null
    amount: number | null = null
    governmentContribution: boolean = false
    description: string | null = null

    isDiscount(): boolean {
        return this.type == 'SC'
    }

    getValue(invoiceTaxable) {
        if(this.percentage) {
            let value = round( invoiceTaxable * this.percentage / 100 )
            return this.isDiscount() ? -value : value
        }

        if(this.amount) {
            return this.isDiscount() ? -this.amount : this.amount
        }

        return 0
    }
}

export class Line extends Entity {
    position: number
    description: string
    quantity: number | null = null
    unitOfMeasure: string
    cost: number
    vat: number

    references: Reference[]
    expanded: boolean | undefined = undefined

    _total: number | null = null

    get total(): number {
        return round(this.taxable + this.tax)
    }

    get taxable(): number {
        if (!this.cost) return 0

        if (!this.quantity) return round(this.cost)

        return round(this.quantity * this.cost)
    }

    get tax(): number {
        if (!this.vat) return 0
        return round(this.taxable * (this.vat / 100));
    }

    isExpanded() {
        return this.expanded === true
    }

    hasReferences() {
        return this.references?.length > 0
    }

    addReference(reference: Reference) {
        if(this.references == undefined) this.references = []

        this.references.push(reference)
    }
}

export class Reference {
    type: string
    textReference: string | null
    numberReference: number | null
    dateReference: Moment | null
}

export class Attachment {
    name: string
    description: string | null = null
    type: string | null = null
    compressionAlgorithm: string | null = null
    base64Data: string | null = null
}
