<table mat-table [dataSource]="lines" class="input-table" multiTemplateDataRows #linesTable>
    <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef i18n="@@line_description">Descrizione Linea</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field>
                    <textarea name="description-{{element.position}}" #description="ngModel" (ngModelChange)="onChange(lines)" maxlength="1000"
                              matInput required [disabled]="disabled" cdkTextareaAutosize [(ngModel)]="element.description"></textarea>
                <mat-error *ngIf="description.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef i18n="@@quantity">Quantit&agrave;</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field class="small">
                <input type="number" name="quantity-{{element.position}}" (ngModelChange)="onChange(lines)"
                       #quantity="ngModel" [disabled]="disabled" matInput [(ngModel)]="element.quantity"/>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="unitOfMeasure">
        <th mat-header-cell *matHeaderCellDef i18n="@@unit_of_measurement">Unit&agrave; di misura</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field class="small">
                <input name="unit-{{element.position}}" (ngModelChange)="onChange(lines)"
                       #unit="ngModel"
                       [disabled]="disabled"
                       matInput [(ngModel)]="element.unitOfMeasure"/>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef i18n="@@unit_cost">Prezzo Unitario</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field class="small">
                <input type="number" name="cost-{{element.position}}" #cost="ngModel" (ngModelChange)="onChange(lines)"
                       matInput required
                       [disabled]="disabled"
                       [(ngModel)]="element.cost"/>
                <label matSuffix>€</label>
                <mat-error *ngIf="cost.hasError('required')" i18n="@@require_field">Campo obbligatorio</mat-error>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef i18n="@@vat">IVA</th>
        <td mat-cell *matCellDef="let element">
            <mat-form-field class="small">
                <mat-select name="vat-{{element.position}}" #vat="ngModel" required (ngModelChange)="onChange(lines)" [disabled]="disabled" [(ngModel)]="element.vat">
                    <mat-option
                        *ngFor="let vat of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]"
                        [value]="vat">
                        {{ vat }}%
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="vat.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef i18n="@@price">Prezzo</th>
        <td mat-cell *matCellDef="let element">{{ element.taxable  | formatCurrency }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <div style="display: flex; flex-flow: row-reverse">
                <button type="button" mat-icon-button [matMenuTriggerFor]="lineMenu" *ngIf="!disabled">
                    <mat-icon class="mat-24">more_vert</mat-icon>
                </button>
                <mat-menu #lineMenu="matMenu">
                    <button mat-menu-item (click)="removeLine($event, element)">
                        <mat-icon>delete</mat-icon>
                        <span i18n="@@remove">Elimina</span>
                    </button>
                    <button mat-menu-item (click)="addReferences($event, element)">
                        <mat-icon>note_add</mat-icon>
                        <span i18n="@@add_management_data">Aggiungi altri dati gestionali</span>
                    </button>
                </mat-menu>
                <button mat-icon-button (click)="onExpandLine(element, $event)">
                    <mat-icon *ngIf="!element.expanded">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="element.expanded">keyboard_arrow_up</mat-icon>
                </button>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="details">
        <td mat-cell [attr.colspan]="linesColumns.length" *matCellDef="let element">
            <div class="detailsCell"
                 [@detailExpand]="element.isExpanded() ? 'expanded' : 'collapsed'">

                <section *ngIf="element.hasReferences()" class="references">
                    <header><h5 i18n="@@management_data">Altri dati gestionali</h5></header>

                    <div *ngFor="let reference of element.references; let i = index" class="reference">

                        <mat-form-field>
                            <mat-label i18n="@@kind_of_data">Tipo dato</mat-label>

                            <input name="reference-{{i}}" #type="ngModel" (ngModelChange)="onChange(lines)" matInput required maxlength="10"
                                   [disabled]="disabled" [(ngModel)]="reference.type"/>
                            <mat-error *ngIf="type.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="textReference">
                            <mat-label i18n="@@text_reference">Riferimento testo</mat-label>

                            <input name="textReference-{{i}}" (ngModelChange)="onChange(lines)" matInput maxlength="60"
                                   [disabled]="disabled" [(ngModel)]="reference.textReference"/>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label  i18n="@@reference_number">Riferimento numero</mat-label>

                            <input type="number" name="numberReference-{{i}}" (ngModelChange)="onChange(lines)"
                                   matInput [disabled]="disabled" [(ngModel)]="reference.numberReference"/>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label  i18n="@@reference_date">Riferimento data</mat-label>
                            <input name="dateReference-{{i}}" matInput (ngModelChange)="onChange(lines)"
                                   [matDatepicker]="dateReference" [disabled]="disabled" [(ngModel)]="reference.dateReference">
                            <mat-datepicker-toggle matSuffix [for]="dateReference"></mat-datepicker-toggle>
                            <mat-datepicker #dateReference></mat-datepicker>
                        </mat-form-field>

                        <div>
                            <button type="button" mat-icon-button matSuffix (click)="removeReference($event, reference, element)" *ngIf="disabled">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>

                </section>


            </div>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="linesColumns"></tr>
    <tr mat-row class="lineRow" *matRowDef="let row; columns: linesColumns;"></tr>
    <tr mat-row class="detailsRow" *matRowDef="let row; columns: ['details'];"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="linesColumns.length"
            style="text-align: center">
            <p  i18n="@@no_line">Nessuna linea presente</p>
            <mat-error *ngIf="form.hasError('emptyfiscalDocument') && !form.pristine"  i18n="@@one_line_at_least">Il preventivo deve contenere almeno una linea</mat-error>
        </td>
    </tr>

</table>

