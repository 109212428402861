import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, of, tap} from "rxjs";
import {PricedItem, PriceList} from "../price-lists/price-lists.component";
import {NgForm} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PriceListRepository} from "../priceList.repository";
import {MatTable} from "@angular/material/table";

@Component({
    selector: 'app-price-list',
    templateUrl: './price-list.component.html',
    styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {

    displayedColumns: string[] = ['code', 'description', 'unitOfMeasure', 'cost', 'actions'];
    loading: boolean = false;
    priceListObservable: Observable<PriceList | undefined>;
    @ViewChild('itemsTable') itemsTable: MatTable<PricedItem>

    constructor(private router: Router, private route: ActivatedRoute, private priceListRepository: PriceListRepository) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let id = params.get('id');
            if (!id || id === 'new') {
                this.priceListObservable = of(new PriceList())
            } else {
                this.priceListObservable = this.priceListRepository.findById(id).pipe(tap(value => console.log("hehe", value)))
            }
        });
    }

    save(priceList: PriceList, form: NgForm) {
        if (!form.valid) return

        this.loading = true;
        this.priceListRepository.save(priceList).then(value => this.loading = false);
    }

    discardChanges(priceList: PriceList) {

    }

    addNewItem(priceList: PriceList) {
        let item = new PricedItem()

        priceList.items.push(item)
        this.itemsTable.renderRows()
    }

    removeItem(event: MouseEvent, priceList: PriceList, item: PricedItem) {
        event.stopPropagation()
        event.preventDefault()

        let index = priceList.items.findIndex(value => value.code == item.code);
        priceList.items.splice(index, 1)
        this.itemsTable.renderRows()
    }
}
