import * as moment from "moment";
import {Moment} from "moment";
import {Document} from "./document";
import {FiscalDocument} from "./fiscalDocument";
import {Entity} from "./entity";

export class Payment extends Entity {
    // fake property to avoid form control for select
    invoice: any;

    amount: number;
    description: string | null = null;
    invoiceDate: Moment;
    dueDate: Moment | null = null;
    paidDate: Moment | null = null;
    receipt: Document | null;
    vat: number = 21;
    vatAmount: number = 0
    invoiceNumber: string;
    invoiceId: string;

    type: { id, name } | null = null
    iban: string | null = null
    bank: string | null = null

    override getDocuments(): Document[] {
        return [this.receipt!].filter(value => !!value)
    }

    isPaid(): boolean {
        return this.paidDate != null
    }

    isOverdue(): boolean {
        return this.paidDate == null && moment().isAfter(this.dueDate)
    }

    // IMPORTANT this method is mirrored in a firebase function
    syncPaymentWithInvoice(invoice: FiscalDocument) {
        this.amount = invoice.total
        this.invoiceNumber = invoice.number
        this.invoiceId = invoice.id
        this.description = invoice.description
        this.invoiceDate = invoice.date
        this.dueDate = invoice['dueDate'] || null
        this.paidDate = invoice['paidDate'] || null
        this.vatAmount = invoice.tax
    }

    isBankTransfer() {
        return this.type?.id == bankTransfer.id
    }

    getAccountingMonth() {
        return this.invoiceDate.month()
    }

    getAccountingYear() {
        return this.invoiceDate.year()
    }
}

export const bankTransfer = {
    name: 'Bonifico',
    id: 'MP05'
};
export const paymentTypes = [
    {
        name: 'Contanti',
        id: 'MP01'
    },
    {
        name: 'Assegno',
        id: 'MP02'
    },
    {
        name: 'RIBA',
        id: 'MP12'
    },
    {
        name: 'Assegno circolare',
        id: 'MP03'
    },
    bankTransfer
]
