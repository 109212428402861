import {Component, OnInit} from '@angular/core';
import {countries, districts} from "../utils";
import {DocumentStorageService} from "../document-storage.service";
import {BusinessRepository} from "../authentication/business.repository";
import {Business, TaxRegime, taxRegimes} from "../model/business";
import {Bouncer} from "../bouncer.service";
import {Transaction} from "@angular/fire/firestore";
import {NotificationService} from "../notification.service";
import {FormComponentV2} from "../components/formV2.component";
import {NavigatorService} from "../navigator.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    templateUrl: './business-administration.component.html',
    styleUrls: ['./business-administration.component.scss']
})
export class BusinessAdministrationComponent extends FormComponentV2<Business> implements OnInit {

    business: Business
    taxRegimes: TaxRegime[] = taxRegimes

    constructor(private businessRepository: BusinessRepository, private bouncer: Bouncer, private route: ActivatedRoute,
                navigatorService: NavigatorService,
                notificationService: NotificationService,
                documentStorage: DocumentStorageService,) {
        super(documentStorage, navigatorService, notificationService, businessRepository)
    }

    ngOnInit(): void {
        this.business = this.route.snapshot.data.business

        if(this.business.hasMissingData()) {
            this.notificationService.notifyError($localize `:@@complete_business_details:Per poter utilizzare l'applicazione, devi completare i dati della tua azienda`)
        }
    }

    override async doUpdate(transaction: Transaction, business: Business) {
        await this.businessRepository.update(transaction, business)
        this.bouncer.storeBusiness(business)
    }

    async doCreate(transaction: Transaction, entity: Business) {
        throw Error("Business component does not implement doCreate")
    }

    protected readonly countries = countries;
    protected readonly districts = districts;
}
