import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatTable} from "@angular/material/table";
import {Line, PriceModifier, Reference} from "../../model/invoice";
import {PriceModifiersComponent} from "../price-modifiers/price-modifiers.component";
import {generateId} from "../../utils";
import {GovernmentContributionsComponent} from "../government-contributions/government-contributions.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatDialog} from "@angular/material/dialog";
import {PricedItemSelectorComponent} from "../../price-lists/priced-item-selector/priced-item-selector.component";
import {PricedItem} from "../../price-lists/price-lists/price-lists.component";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
    selector: 'lines',
    templateUrl: './lines.component.html',
    styleUrls: ['./lines.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', 'padding-top': '0', 'padding-bottom': '0'})),
            state('expanded', style({height: '*', 'padding-top': '*', 'padding-bottom': '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LinesComponent),
            multi: true,
        },
    ],
})
export class LinesComponent implements OnInit, ControlValueAccessor {

    linesColumns: string[] = ['description', 'quantity', 'unitOfMeasure', 'cost', 'vat', 'total', 'actions'];

    onChange = (value: Line[]) => {
    };
    onTouched = () => {
    };
    touched = false;
    @Input() disabled = false;

    lines: Line[] = [];
    @Input() priceModifiers: PriceModifier[]
    @Input() form: NgForm;

    @ViewChild('linesTable') lineTable: MatTable<Line>
    @ViewChild(PriceModifiersComponent) priceModifiersComponent: PriceModifiersComponent
    @ViewChild(GovernmentContributionsComponent) governmentContributionsComponent: GovernmentContributionsComponent


    constructor(public dialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    addNewLine() {
        let line = new Line();
        line.position = this.lines.length + 1
        line.id = generateId()
        this.lines.push(line)
        this.lineTable.renderRows()
        this.onChange(this.lines)
    }

    removeLine($event: MouseEvent, element,) {
        let index = this.lines.findIndex(value => value.id == element.id);
        this.lines.splice(index, 1)
        this.lineTable.renderRows()
        this.onChange(this.lines)
    }

    onExpandLine(expandedLine: Line, event) {
        expandedLine.expanded = !expandedLine.expanded
        event.preventDefault()
        event.stopPropagation()
    }

    addReferences(event: MouseEvent, line: Line) {
        line.addReference(new Reference())
        line.expanded = true
        this.lineTable.renderRows()
        this.onChange(this.lines)
    }

    removeReference(event: MouseEvent, reference: Reference, line: Line) {
        event.preventDefault()

        let index = line.references.findIndex(value => reference == value);
        line.references.splice(index, 1)
        this.lineTable.renderRows()
        this.onChange(this.lines)
    }

    addNewPricedItem() {
        const dialogRef = this.dialog.open(PricedItemSelectorComponent, {
            width: '90%'
        });

        dialogRef.afterClosed().subscribe((selection: SelectionModel<PricedItem>) => {
            console.log("called", selection)

            if (selection.hasValue()) {
                selection.selected.forEach(item => {
                    let line = new Line();
                    line.description = item.description
                    line.cost = item.cost
                    line.quantity = 1
                    line.vat = 20
                    line.unitOfMeasure = item.unitOfMeasure
                    line.position = this.lines.length != 0 ? this.lines[this.lines.length - 1].position + 1 : 1
                    this.lines.push(line)
                })

                this.lineTable.renderRows()
                this.onChange(this.lines)

            }
        });
    }

    writeValue(lines: Line[]) {
        this.lines = lines
        this.lineTable.renderRows()
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

}
