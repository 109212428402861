<input name="fileUpload" type="file" class="file-input" (change)="onDocumentSelected($event)" #fileUpload>

<mat-form-field>
    <mat-label>
        <span *ngIf="label">{{ label }}</span>
        <span *ngIf="!label" i18n="@@document">Documento</span>
    </mat-label>

    <input matInput [required]="required" [placeholder]="placeholder" [(ngModel)]="this.document" readonly="true">

    <a mat-icon-button matSuffix [href]="getDownloadUrl()" *ngIf="isDownloadable()" download target="_blank">
        <mat-icon *ngIf="document">download</mat-icon>
    </a>

    <button type="button" mat-icon-button matSuffix *ngIf="!isDownloadable() && !disabled" (click)="selectDocument( $event, fileUpload)">
        <mat-icon>attach_file</mat-icon>
    </button>

    <button type="button" mat-icon-button matSuffix *ngIf="isDownloadable() && !disabled" (click)="remove($event)">
        <mat-icon>delete_forever</mat-icon>
    </button>
</mat-form-field>

