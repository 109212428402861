import {Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {map, Observable} from "rxjs";
import {Repository} from "../../persistence/repository";
import {Entity} from "../../model/entity";

@Component({
    selector: 'list-layout',
    templateUrl: './list-layout.component.html',
    styleUrls: ['./list-layout.component.scss']
})
export class ListLayoutComponent implements OnInit {

    @Input() entityLabelling: { singular: string, plural: string } = {singular: '', plural: ''}
    @Input() addIcon: string = 'add'
    @Input() repository: Repository<any>

    listObservable: Observable<Entity[]>
    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;


    ngOnInit(): void {
        if (this.repository) {
            this.listObservable = this.repository.getAll()
        }
    }

    applyFilter(event: KeyboardEvent) {
        let filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
        this.listObservable = this.repository.getAll().pipe(map(list => list.filter(entity => entity.name.trim().toLowerCase().includes(filter.trim().toLowerCase()))));

    }
}
