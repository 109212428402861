import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {Entity} from "./model/entity";

@Injectable({
    providedIn: 'root'
})
export class NavigatorService {

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    onEntitySaved(entity: Entity) {
        const url = this.router.createUrlTree([], {relativeTo: this.route}).toString()
        if (url.endsWith('new')) {
            this.router.navigate([url.replace('new', ''), entity.id])
        }
    }

    goToAuthenticationPage() {
        this.router.navigate(['/auth'])
    }

    goToHomePage() {
        return this.router.navigate(['/clients'])
    }

    goToVerificationPage() {
        return this.router.navigate(['/verification'])
    }

    getEmailVerifiedRedirectUrl() {
        let url = window.location.toString().replace('registration/edilie', 'mybusiness');
        return url;
    }

    goToWelcomePage() {
        return this.router.navigate(['/welcome'])
    }

    goToBusinessPage() {
        return this.router.navigate(['/mybusiness'])
    }
}
