<form #form="ngForm" (ngSubmit)="save(employee)">
    <article class="page">
        <header>
            <h1 i18n="@@employee">Dipendente</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above" [disabled]="loading"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above"
                        (click)="discardChanges(employee)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>

        <div class="content">
            <mat-tab-group>
                <mat-tab label="Dettagli" i18n-label="@@details" labelClass="tab-label">
                        <div class="tab-content">
                            <mat-card class="infoCard employee">

                                <mat-card-header>
                                    <mat-icon mat-card-avatar>badge</mat-icon>
                                    <mat-card-title i18n="@@personal_information">Anagrafica</mat-card-title>
                                </mat-card-header>

                                <mat-card-content>
                                    <mat-form-field class="flexible-size">
                                        <mat-label i18n="@@name">Nome</mat-label>
                                        <input name="fullName" matInput #fullName="ngModel" required [(ngModel)]="employee.fullName">
                                        <mat-error *ngIf="fullName.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="fixed-size">
                                        <mat-label i18n="@@tax_code">Codice Fiscale</mat-label>
                                        <input name="taxNumber" matInput #taxNumber="ngModel" required maxLength="16" minlength="16"
                                               [(ngModel)]="employee.taxNumber">
                                        <mat-error *ngIf="taxNumber.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                                        <mat-error *ngIf="taxNumber.hasError('minLength') as error" i18n="@@tax_code_min_length">Il codice fiscale deve essere
                                            composto da 16 caratteri, immessi {{ error['actualLength'] }}
                                        </mat-error>
                                    </mat-form-field>

                                    <document-input name="id_document" type="id document" label="Documento di riconoscimento" class="flexible-size"
                                                    [required]="true" [batch]="documentStorageBatch"
                                                    [(ngModel)]="employee.identificationDocument">
                                    </document-input>

                                    <mat-form-field >
                                        <mat-label i18n="@@address">Indirizzo</mat-label>
                                        <input name="address" matInput [(ngModel)]="employee.address">
                                    </mat-form-field>

                                    <mat-form-field class="flexible-size">
                                        <mat-label i18n="@@phone_number">Telefono</mat-label>
                                        <input name="phoneNumber" matInput
                                               pattern="\+{0,1}[0-9]+" minlength="4"
                                               #phoneNumber="ngModel" [(ngModel)]="employee.phoneNumber">
                                        <mat-error *ngIf="phoneNumber.hasError('pattern')" i18n="@@invalid_phone_number">Numero telefono non valido</mat-error>
                                        <mat-error *ngIf="phoneNumber.hasError('minlength')" i18n="@@invalid_phone_number">Numero telefono non valido</mat-error>
                                    </mat-form-field>


                                    <mat-form-field class="flexible-size">
                                        <mat-label i18n="@@birth_place">Luogo di nascita</mat-label>
                                        <input name="birthPlace" matInput [(ngModel)]="employee.birthPlace">
                                    </mat-form-field>

                                    <mat-form-field class="fixed-size">
                                        <mat-label i18n="@@birth_date">Data di nascita</mat-label>
                                        <input name="birthDate" matInput [matDatepicker]="picker" [(ngModel)]="employee.birthDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>

                                </mat-card-content>
                            </mat-card>

                            <mat-card class="infoCard details">
                                <mat-card-header>
                                    <mat-icon mat-card-avatar>build</mat-icon>

                                    <mat-card-title i18n="@@hiring_details">Dettagli incarico</mat-card-title>
                                </mat-card-header>

                                <mat-card-content>
                                    <mat-form-field class="flexible-size">
                                        <mat-label i18n="@@function">Mansione</mat-label>
                                        <input name="function" matInput
                                               #f="ngModel" required
                                               [(ngModel)]="employee.function">
                                        <mat-error *ngIf="f.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="fixed-size">
                                        <mat-label i18n="@@hourly_pay">Paga oraria</mat-label>
                                        <input name="hourlyRate" matInput
                                               #hourlyRate="ngModel" required
                                               [(ngModel)]="employee.hourlyRate">
                                        <mat-error *ngIf="hourlyRate.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                                    </mat-form-field>

                                    <document-input name="job_contract" type="job contract" label="Contratto di lavoro" i18n-label="@@job_contract" class="flexible-size"
                                                    [batch]="documentStorageBatch"
                                                    [(ngModel)]="employee.jobContract"></document-input>

                                    <document-input name="employment_center" type="employment center" label="Striscetta collocamento" i18n-label="employment_center"  class="flexible-size"
                                                    [batch]="documentStorageBatch"
                                                    [(ngModel)]="employee.employmentCenterDocument"></document-input>

                                    <document-input name="c2" type="c2" label="C2 storico" i18n-label="c2"  class="flexible-size"
                                                    [batch]="documentStorageBatch"
                                                    [(ngModel)]="employee.c2Document"></document-input>

                                    <document-input type="termination letter" label="Lettera di licenziamento" name="termination_letter" i18n-label="@@termination_letter"  class="flexible-size"
                                                    [batch]="documentStorageBatch"
                                                    [(ngModel)]="employee.terminationLetter"></document-input>
                                </mat-card-content>
                            </mat-card>
                        </div>
                </mat-tab>

                <mat-tab label="Presenze e buste paga" i18n-label="@@attendance_payslips" labelClass="tab-label">
                    <ng-template matTabContent>
                        <div class="tab-content">
                            <div class="flex-column">

                                <leave-profile name="leaveProfile" [(ngModel)]="employee.leaveProfile"></leave-profile>

                                <sick-leave-profile name="sickLeaveProfile" [batch]="documentStorageBatch"
                                                [(ngModel)]="employee.sickLeaveProfile"></sick-leave-profile>
                            </div>

                            <div class="flex-column">
                                <payroll name="payroll" [batch]="documentStorageBatch" [(ngModel)]="employee.payroll.records"></payroll>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Visite mediche e formazione" i18n-label="@@medical_and_education_records" labelClass="tab-label">
                    <ng-template matTabContent>
                        <div class="tab-content" *ngIf="suppliersObservable | async as suppliers">
                                <medical-profile name="medicalProfile" [(ngModel)]="employee.medicalProfile.records" [suppliers]="suppliers"
                                                 [batch]="documentStorageBatch"></medical-profile>

                            <education-profile name="educationProfile" [(ngModel)]="employee.educationProfile.records" [suppliers]="suppliers"
                                             [batch]="documentStorageBatch"></education-profile>
                        </div>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>

        </div>
    </article>

</form>
