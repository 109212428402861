import {EventEmitter, Injectable} from '@angular/core';
import {
    Auth,
    authState,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut,
    updateProfile
} from "@angular/fire/auth";
import {NavigatorService} from "./navigator.service";
import {Business} from "./model/business";
import {User} from "firebase/auth"
import {BusinessRepository} from "./authentication/business.repository";
import {getInspectedBusiness} from "./utils";

@Injectable({
    providedIn: 'root'
})
export class Bouncer {

    registrationCompletedEmitter = new EventEmitter<any>();
    loginCompletedEmitter = new EventEmitter<{ user: User, business: Business, config: any }>();

    constructor(private auth: Auth, private navigator: NavigatorService, private businessRepository: BusinessRepository) {
    }

    onStateChange() {
        authState(this.auth).subscribe(user => {
            if (!user) {
                console.log("not logged in", user)
                this.cleanUserData()
                return
            }

            if (!user.emailVerified) return

            this.updateSessionData(user, Bouncer.isFirstLogin(user)).then(value => {
                this.loginCompletedEmitter.emit(value);
            })
        })
    }

    activateAuth() {
        this.onStateChange()
    }

    private static isFirstLogin(user: User) {
        return user.metadata.lastSignInTime == user.metadata.creationTime;
    }

    signIn(email: string, password: string) {
        return signInWithEmailAndPassword(this.auth, email, password)
            .then((result) => {
                console.log("logged in", result.user)
                return this.updateSessionData(result.user)
            }).then(value => this.loginCompletedEmitter.emit(value))
            .catch((error) => window.alert(error.message))
    }

    async updateSessionData(user, refresh: boolean = false): Promise<{ user: User, business: Business, config: any }> {
        let idToken = await user.getIdTokenResult(refresh);

        let businessId = idToken.claims['businessId'] as string;
        let config = {
            erp: idToken.claims['erp'] as boolean || false,
            invoicing: idToken.claims['invoicing'] as boolean || false,
            superAdmin: idToken.claims['roles'] ? idToken.claims['roles'].includes('SUPER_ADMIN') : false
        }

        let value: { businessId: string; config: { erp: boolean; invoicing: boolean; superAdmin: any } } = {businessId, config};
        return this.updateStorageData(value.businessId, value.config, user)
    }

    async getAccessToken() {
        return this.auth.currentUser!.getIdToken();
    }

    private async updateStorageData(id, config, user: User): Promise<{ user: User, business: Business, config: any }> {
        if (!id) throw new Error("no business id")

        let business = await this.businessRepository.getByIdAsPromise(id);
        if (!business) throw new Error(`no business ${id} found in db`)

        this.storeBusiness(business)
        this.storeUser(user)
        this.storeConfig(config)
        return {user, business, config}

    }

    storeConfig(config: any) {
        localStorage.setItem('config', JSON.stringify(config))
    }

    storeUser(user: any) {
        localStorage.setItem('user', JSON.stringify(user))
    }

    storeBusiness(business: Business) {
        localStorage.setItem('business', JSON.stringify(business))
    }

    getConfig() {
        let config = localStorage.getItem('config');
        if (!config) throw new Error("no config found")
        return JSON.parse(config!)
    }

    async signUp(email: string, password: string, name: string): Promise<string> {
        let userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
        await updateProfile(userCredential.user, {displayName: name})
        await sendEmailVerification(userCredential.user, {url: this.navigator.getEmailVerifiedRedirectUrl()})

        this.registrationCompletedEmitter.emit(userCredential.user)
        return userCredential.user.uid
    }

    logout() {
        return signOut(this.auth).then(() => {
            console.log("signign out")
            this.cleanUserData();

            return this.navigator.goToWelcomePage()
        })
    }

    cleanUserData() {
        localStorage.removeItem('user')
        localStorage.removeItem('business')
        localStorage.removeItem('config')
        localStorage.removeItem("inspectedBusiness")
    }

    isLoggedIn() {
        return !!this.getUser()
    }

    private getUser(): User | null {
        let storedUser = localStorage.getItem('user')
        let user = storedUser ? JSON.parse(storedUser) : null
        return this.auth.currentUser || user;
    }

    onLoginCompleted() {
        return this.loginCompletedEmitter
    }

    isVerified() {
        return this.getUser()?.emailVerified
    }

    storeInspectedBusiness(business: Business) {
        if (business) {
            localStorage.setItem('inspectedBusiness', JSON.stringify(business))
        } else {
            localStorage.removeItem('inspectedBusiness')
        }
    }

    getInspectedBusiness() {
        return getInspectedBusiness()
    }

}
