import {Component} from "@angular/core";
import {DocumentStorageBatch, DocumentStorageService} from "../document-storage.service";
import {Document} from "../model/document";
import {Repository} from "../persistence/repository";
import {NavigatorService} from "../navigator.service";
import {NotificationService} from "../notification.service";
import {Transaction} from "@angular/fire/firestore";
import {FormComponent} from "./form.component";
import {Address} from "../model/address";
import {Entity} from "../model/entity";

@Component({template: ''})
export abstract class FormComponentV2<E extends Entity, T extends Entity = E> extends FormComponent {

    loading: boolean;
    documentStorageBatch: DocumentStorageBatch;

    constructor(
        protected documentStorage: DocumentStorageService,
        protected navigatorService: NavigatorService,
        protected notificationService: NotificationService,
        protected repository: Repository<T>
    ) {
        super()
        this.documentStorageBatch = this.documentStorage.openBatch();
    }

    private isFormInvalid() {
        let address = this.form.controls.address?.value as Address | undefined
        let addressInvalid = false
        if(address) {
            addressInvalid = !address.street || !address.postcode || !address.nation || !address.city || !address.district
        }

        return !this.form.valid ;
    }

    async save(entity: E) {
        if(this.form != null && this.isFormInvalid()) {
            this.form.control.markAllAsTouched();
            console.log("Form invalid", this.form.errors, this.form.controls)
            this.notificationService.notifyError($localize `:@@cannot_save:Impossibile salvare, il form contiene errori`)
            return
        }

        try {
            this.startSavingProcess()
            await this.uploadDocuments(entity.getDocuments())

            await this.repository.transaction(async (transaction) => {
                    if (entity.persisted()) {
                        let value = this.doUpdate(transaction, entity);
                        this.documentStorage.markDocumentsDelete(transaction, this.documentStorageBatch.toDelete);
                        this.documentStorageBatch = this.documentStorage.openBatch()
                        ++entity.version
                        return value
                    } else {
                        return this.doCreate(transaction, entity);
                    }
                }
            );

            this.saveCompleted(entity)
        } catch (error) {
            console.log("Error", error)
            this.loading = false
            this.notificationService.notifyError("Si è verificato un errore, impossibile salvare")
        }
    }

    startSavingProcess() {
        this.loading = true;
    }

    saveCompleted(entity: Entity) {
        this.loading = false
        this.form.form.markAsPristine()
        this.navigatorService.onEntitySaved(entity)
        this.notificationService.notify($localize`:@@entity_saved:Salvataggio completato`)
    }

    async uploadDocuments(documents: Document[]) {
        await this.documentStorage.uploadSequentially(documents.filter(value => !value?.isDeleted()))
    }

    discardChanges(entity: Entity) {
        this.reloadPage();
    }

    reloadPage() {
        setTimeout(() => window.location.reload(), 100);
    }

    abstract doCreate(transaction: Transaction, entity: E): Promise<unknown>

    abstract doUpdate(transaction: Transaction, entity: E): Promise<unknown>
}
