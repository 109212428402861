import {Injectable} from '@angular/core';
import {collectionData, doc, getDoc, query, Transaction, where} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {Business} from "../model/business";
import {ProjectConverter} from "../projects-management/project-repository.service";
import {Observable} from "rxjs";
import {Project} from "../model/project";
import {getCurrentBusiness} from "../utils";
import {DocumentConverter} from "../document.repository";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";

@Injectable({
    providedIn: 'root'
})
export class BusinessRepository extends Repository<Business> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('Business'))
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection("businesses").withConverter(this.converter);
    }

    async getByIdAsPromise(id: string): Promise<Business | undefined> {
        let documentReference = doc(this.getCollectionReference(), id);
        let value = await getDoc(documentReference);
        return value.data();
    }

    getAllProjects(): Observable<Project[]> {
        let documentConverter = new DocumentConverter();
        let projectConverter = new ProjectConverter(documentConverter);
        let projectQuery = query(
            this.firestoreWrapper.collectionGroup('projects').withConverter(projectConverter),
            where('businessId', '==', getCurrentBusiness().id)
        )

        return collectionData(projectQuery)

    }

    async update(transaction: Transaction, business: Business) {
        await this.transactionalUpdate(transaction, business.id, {
            name: business.name,
            taxNumber: business.taxNumber,
            phoneNumber: business.phoneNumber,
            address: {
                nation: business.address.nation,
                street: business.address.street,
                city: business.address.city,
                district: business.address.district,
                postcode: business.address.postcode,
            },
            sdi: business.sdi,
            taxRegime: business.taxRegime,
            iban: business.iban,
            bank: business.bank,
            documents: business.getDocuments().filter(value => !value.isDeleted()),
            version: business.version + 1,
        } as Business);
    }
}
