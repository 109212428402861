import {Component} from '@angular/core';
import {environment} from "../../environments/environment";
import {Bouncer} from "../bouncer.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
    navLinks = [
        {
            label: 'Inbound errors',
            path: './inboundMessageErrors',
            index: 0
        }, {
            label: 'Inbound messages',
            path: './inboundMessages',
            index: 1
        }, {
            label: 'Outbound messages',
            path: './outboundMessages',
            index: 2
        }, {
            label: 'Contact requests',
            path: './contactRequests',
            index: 3
        }, {
            label: 'Price lists',
            path: './priceLists',
            index: 4
        },
    ];

    constructor(private httpClient: HttpClient, private bouncer: Bouncer) {
    }


    async sendInvoices() {
        let token = await this.bouncer.getAccessToken()
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${token}`)
        this.httpClient.post(`${environment.hermes.gcp}/sdi/invoices`, {}, {headers}).subscribe({
            next: (value: any) => {
                alert(`Invoices sent ${value.length}`);
            },
            error: err => alert(`error: ${JSON.stringify(err)}`)
        })

    }

    checkGcp() {
        this.httpClient.get(`${environment.hermes.gcp}/sdi/test`, {responseType: 'text'}).subscribe({
            next: (value: any) => alert(`check: ${value}`),
            error: (err) => alert(`error: ${err}`)
        })
    }

    checkGae() {
        this.httpClient.get(`${environment.hermes.url}/sdi/test`, {responseType: 'text'}).subscribe({
            next: (value: any) => alert(`check: ${value}`),
            error: (err) => alert(`error: ${err}`)
        })
    }

    async migrate() {
        let token = await this.bouncer.getAccessToken()
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${token}`)
        this.httpClient.get(`${environment.hermes.gcp}/invoices/migrate`, {headers, responseType: 'text'}).subscribe({
            next: (value: any) => alert(`check: ${value}`),
            error: (err) => alert(`error: ${JSON.stringify(err)}`)
        })
    }
}
